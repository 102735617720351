import { createBrowserHistory } from "history";

//API CALL TYPE
//const TYPE_LOCAL = "LOCAL";
const TYPE_REST = "REST";
//console.log(77, process.env.NODE_ENV);

//resources
// const API_URL =
//   process.env.NODE_ENV === "production" ? "" : "https://dev.senshost.net/api";
//const API_URL = "http://senshost.com:8015/api";
const SOCKET_URL = "mqtt://senshost.com";
//API contexts
//We will pass this to swagger class constractor if we need different base urls
//const DEFAULT = "";
//const SECONDARY = "/something";

export enum APILIST {
  AUTH = "Auth",
  ACCOUNT = "Account",
  ACCOUNTS = "Accounts",
  DEVICE = "Device",
  DEVICES = "Devices",
  DEVICEITEM = "dataValue",
  DEVICEIDENTITY = "device-identity",
  COLLECTION = "Collection",
  EVENT = "events",
  BUILDING = "buildings",
  BATS = "bats",
  ASSET = "assets",
  LEVEL = "level",
  ROOM = "room",
  TRIGGER = "Trigger",
  COLLECTIONITEM = "collection-items",
  Group = "Group",
  Sensor = "DataField",
  SensorValue = "DataValue",
  Actuator = "actuator",
  Attribute = "attribute",
  Action = "Action",
  User = "user",
  Settings = "Settings",
  Dashboard = "dashboard",
  DashboardItem = "dashboardItem",
  Trigger = "Trigger",
}

//CONFIG DATA (Please change here only)
const configs = {
  delay: 500,
  // version: '10',
  dashbordRefreshRate: 1,
  port: 8015,
  appName: "senhost",
  toastDelay: 5000,
  tokenStorage: "TOKEN_PERSIST", 
  socket: SOCKET_URL,
  type: TYPE_REST,
   context: "https://api.prod.senshost.com/api",
   filePath: "https://api.prod.senshost.com/Files",
  // context: "http://127.0.0.1:8002/api",
  // filePath: "http://127.0.0.1:8002/Files",
  context2: "https://api.prod.senshost.com",
  history: createBrowserHistory(),
  requestTimeOut: 100000,
  apiList: APILIST,
  tablePageSize: 10,
};
export default configs;
